import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import logo from '../../assets/walr.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const Logo = styled.img`
    width: 45px;
    margin: 0 1em;
`

const DropdownUserMenu = styled.div`
    .dropdown-menu {
        right: 0.5em;
        left: auto;
        border-radius: 0;
    }
`
const MenuButton = styled.button`
    margin: 0 1em;
    border: 0;

    &:focus{
        border: 0;
    }
`

const Header = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem(`${process.env.REACT_APP_USER_TOKEN}`);

    const onLogOut = () => {
        localStorage.removeItem(`${process.env.REACT_APP_USER_TOKEN}`);
        navigate('/login');
    }

    return (
        <div className='navbar navbar-expand-lg bg-light shadow-sm d-flex justify-content-between'>
            <Link to={_.isNull(token) ? '/login' : '/'}>
                <Logo className='navbar-brand' src={logo} />
            </Link>

            {!_.isNull(token) &&
                <DropdownUserMenu className='dropdown'>
                    <MenuButton className='btn' data-bs-toggle='dropdown' aria-expanded='false'>
                        <FontAwesomeIcon icon={faUser} className="ms-2" />
                    </MenuButton>
                    <ul className='dropdown-menu'>
                        <li>
                            <button className='dropdown-item' onClick={()=>navigate('/change-password')}>
                                Change password
                            </button>
                        </li>
                        <li>
                            <button className='dropdown-item' onClick={onLogOut}>
                                Log out
                            </button>
                        </li>
                    </ul>
                </DropdownUserMenu>

            }
        </div>

    )
}

export default Header;
