import _ from 'lodash';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import ErrorAlert from '../../../../common/components/ErrorAlert/ErrorAlert';
import { REQUEST_STATUS } from '../../../../common/constants';
import { storesProvider } from '../../../../rootStore';

const Form = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2.5em;
    min-width: 500px;
`
const SignUpButton = styled.button`
    background-color: #C3F4EA;
    width: 100%;

    &:hover {
        background-color: #addbd2;
        border-color: #addbd2;
    }
`

const PasswordChange = observer(() => {
    const navigate = useNavigate();
    const [password, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
    const [showPasswordErrorAlert, setShowPasswordErrorAlert] = useState<boolean>(false);
    const { morningConsultSamplerStore } = useContext(storesProvider);
    const { changePassword,
        changingPasswordStatus,
        errorMessage,
        updateChangingPasswordStatus,
        statusForbidden
    } = morningConsultSamplerStore;
    const token = localStorage.getItem(`${process.env.REACT_APP_USER_TOKEN}`);

    useEffect(() => {
        if (_.isNull(token)) {
            navigate('/login');
        }
    }, [])

    useEffect(() => {
        if (statusForbidden) {
            localStorage.removeItem(`${process.env.REACT_APP_USER_TOKEN}`);
            navigate('/login');
        }
    }, [statusForbidden])

    useEffect(() => {
        if (changingPasswordStatus === REQUEST_STATUS.SUCCESS) {
            updateChangingPasswordStatus();
            localStorage.removeItem(`${process.env.REACT_APP_USER_TOKEN}`);
            navigate('/login');
        }
        if (changingPasswordStatus === REQUEST_STATUS.FAILURE) {
            setShowErrorAlert(true);
        }
    }, [changingPasswordStatus]);

    const onPasswordChange = (e: { target: { value: string, }, }) => {
        setNewPassword(e.target.value);
    }

    const onPasswordConfirm = (e: { target: { value: string, }, }) => {
        setConfirmPassword(e.target.value);
    }

    const onSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setShowErrorAlert(false);
        setShowPasswordErrorAlert(false);
        if (_.isEqual(password, confirmPassword) && !_.isNull(token)) {
            await changePassword(token, password);
        } else {
            setShowPasswordErrorAlert(true);
        }
    };

    return (
        <div className='container d-flex flex-column align-items-center'>
            <Form>
                <form onSubmit={onSubmit}>
                    <h3 className='text-center mb-5'>Change password</h3>
                    <p className='text-center mb-1'>New password</p>
                    <input
                        required
                        type='password'
                        className='form-control form-control-lg mb-3'
                        pattern='^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
                        title="Password must contain at least 8 characters, 1 digit, 1 symbol, 1 uppercase letter and 1 lowercase letter" //eslint-disable-line
                        onChange={onPasswordChange} />
                    <p className='text-center mb-1'>Confirm new password</p>
                    <input
                        required
                        type='password'
                        className='form-control form-control-lg mb-3'
                        onChange={onPasswordConfirm} />
                    {showPasswordErrorAlert &&
                        <ErrorAlert message='Passwords do not match.' onClose={() => setShowPasswordErrorAlert(false)} />}
                    {showErrorAlert &&
                        <ErrorAlert message={errorMessage} onClose={() => setShowErrorAlert(false)} />}
                    <SignUpButton
                        className='btn btn-lg mb-5 border'
                        type='submit'>
                        Change password
                    </SignUpButton>

                </form>
            </Form>
        </div>
    );
});

export default PasswordChange;
