import React from 'react';
import styled from 'styled-components';

const Spinner = styled.div`
    color: #C3F4EA;
    margin: 2em auto;
`

const LoadingSpinner = () => (
    <Spinner className='spinner-border' role='status'>
        <span className='sr-only'></span>
    </Spinner>

);

export default LoadingSpinner;
