
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './common/components/Header/Header';
import Login from './modules/MorningConsultSampler/views/Login/Login';
import BidsOverview from './modules/MorningConsultSampler/views/BidsOverview/BidsOverview';
import PasswordChange from './modules/MorningConsultSampler/views/PasswordChange/PasswordChange';
import { rootStore, storesProvider } from './rootStore';

const App = (() => {

    return (
        <storesProvider.Provider value={rootStore}>
            <BrowserRouter>
                <Header />
                <>
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/change-password" element={<PasswordChange />} />
                        <Route path="/" element={<BidsOverview />} />
                    </Routes>
                </>
            </BrowserRouter>
        </storesProvider.Provider>
    );
});

export default App;
