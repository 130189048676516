export const BID_STATUS_LIST = ['live', 'closed', 'all'];

export const headers = [
    'mc. country',
    'mc. bid id',
    'mc. remaining completes',
    'mc. achieved completes',
    'mc. active sessions',
    'mc. over quotas',
    'mc. conversion'];

export const expandedListHeaders = [
    'mc. quota',
    'mc. quota id',
    'l. cpi',
    'mc. cost per interview',
    'mc. complete',
    'mc. failure',
    'mc. over quota',
    'mc. quality termination',
    'mc. screenout',
    'mc. timeout',
    'mc. in progress',
    'l. earnings per click',
    'mc. interview cost',
    'l. cost amount',
    ''
];

export enum REQUEST_STATUS {
    SENDING,
    SUCCESS,
    FAILURE,
}

export const bidStates = {
    0: 'active',
    1: 'closed',
    2: 'all',
}

export const mapGender = {
    '1': 'male',
    '2': 'female',
}

export const mapEducation = {
    '1': 'No qualifications',
    '2': 'GCSE/O-Levels or equivalent',
    '3': 'Highers/A-Levels or equivalentle',
    '4': 'College/vocational or technical training/qualification',
    '5': 'University undergraduate',
    '6': 'Postgraduate qualification',
}
//countries: gb, us, ca, au, ie, de, nl, no, at, be, cz, vn, ch, ro, ае
export const countries_1 = ['gb','us', 'ca', 'au', 'ie', 'de', 'nl', 'no', 'at', 'be'];
export const countries_2 = ['cz', 'vn', 'ch', 'ro', `ae`];