import { makeAutoObservable, observable } from 'mobx';
import {
    Bid,
    BidQuota,
    LoginResponse,
    RootStoreProps,
} from '../../../common/interfaces';
import {
    bidStates,
    countries_1,
    countries_2,
    REQUEST_STATUS,
} from '../../../common/constants';
import _ from 'lodash';
import {
    countryNameByCode,
    quotaNameByQualifications,
} from '../../../common/helpers';

export interface MorningConsultSamplerStoreProps {
    signUp: (
        email: string,
        password: string,
        firstname: string,
        lastname: string
    ) => Promise<void>;
    creatingUserStatus: REQUEST_STATUS | undefined;
    updateCreatingUserStatus: () => void;
    logIn: (email: string, password: string) => Promise<void>;
    loggingUserInStatus: REQUEST_STATUS | undefined;
    errorMessage: string;
    allBids: Array<Bid>;
    bids: Array<Bid>;
    fetchBids: (token: string, paginationToken?: string) => void;
    fetchingBidsStatus: REQUEST_STATUS | undefined;
    loadingMoreStatus: REQUEST_STATUS | undefined;
    filterBids: (searchQuery: string, filterState: number) => void;
    fetchQuotaSessions: (bidID: string, token: string) => void;
    bidQuotas: Array<BidQuota>;
    fetchingQuotasStatus: REQUEST_STATUS | undefined;
    paginationToken: string | undefined;
    onChangeCPI: (survey_id: string | null, cpi: number) => void;
    updateSurveyCPI: (
        token: string,
        survey_id: string | null,
        cpi: number
    ) => void;
    updatingSurveyID: string;
    updatingSurveyCPIStatus: REQUEST_STATUS | undefined;
    changePassword: (token: string, password: string) => Promise<void>;
    changingPasswordStatus: REQUEST_STATUS | undefined;
    updateChangingPasswordStatus: () => void;
    exportExcel: (
        rangeStart: string,
        rangeEnd: string,
        includeQuotaLevel: boolean,
        isLoading: any
    ) => Promise<void>;
    exportExcelStatus: REQUEST_STATUS | undefined;
    updateExportexcelStatus: () => void;
    statusForbidden: boolean;
    fetchingRemainingCountries: boolean;
}

export class MorningConsultSamplerStore implements MorningConsultSamplerStoreProps {
    public rootStore: RootStoreProps;

    constructor(rootStore: RootStoreProps) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
    }

    @observable
    public statusForbidden = false;

    @observable
    public creatingUserStatus: REQUEST_STATUS | undefined = undefined;

    @observable
    public errorMessage = '';

    public signUp = async (
        email: string,
        password: string,
        firstname: string,
        lastname: string
    ) => {
        this.rootStore.walrIntegrationService
            .createUser(email, password, firstname, lastname)
            .then((_data: any) => {
                this.creatingUserStatus = REQUEST_STATUS.SUCCESS;
            })
            .catch((err: { detail: string, }) => {
                this.errorMessage = err.detail;
                this.creatingUserStatus = REQUEST_STATUS.FAILURE;
            });
    };

    public updateCreatingUserStatus = () => {
        this.creatingUserStatus = undefined;
    };

    @observable
    public loggingUserInStatus: REQUEST_STATUS | undefined = undefined;

    public logIn = async (email: string, password: string) => {
        this.errorMessage = '';
        this.loggingUserInStatus = REQUEST_STATUS.SENDING;
        this.rootStore.walrIntegrationService
            .userLogin(email, password)
            .then((data: LoginResponse) => {
                localStorage.setItem(
                    `${process.env.REACT_APP_USER_TOKEN}`,
                    `${data.access_token}`
                );
                this.statusForbidden = false;
                this.loggingUserInStatus = REQUEST_STATUS.SUCCESS;
            })
            .catch((err: { detail: string, }) => {
                this.errorMessage = err.detail;
                this.loggingUserInStatus = REQUEST_STATUS.FAILURE;
            });
    };

    @observable
    public allBids: Array<Bid> = [];

    @observable
    public bids: Array<Bid> = [];

    @observable
    public paginationToken: string | undefined = undefined;

    @observable
    public fetchingBidsStatus: REQUEST_STATUS | undefined = undefined;

    @observable
    public loadingMoreStatus: REQUEST_STATUS | undefined = undefined;

    @observable
    public fetchingRemainingCountries = false;

    public fetchBids = async (token: string, paginationToken?: string) => {
        if (paginationToken) {
            this.loadingMoreStatus = REQUEST_STATUS.SENDING;
        } else {
            this.fetchingBidsStatus = REQUEST_STATUS.SENDING;
        }
        const countries = this.fetchingRemainingCountries
            ? countries_2
            : countries_1;

        this.rootStore.walrIntegrationService
            .getSupplierBids(
                token,
                countries,
                _.isEqual(paginationToken, 'fetchRemainingCountries')
                    ? undefined
                    : paginationToken
            )
            .then((data: { bids: Array<Bid>, }) => {
                this.paginationToken = _.get(data, 'pagination_token', undefined);
                if (_.isUndefined(this.paginationToken)) {
                    const availableBidsCountries = _.uniq(
                        _.map(this.allBids, ({ country_id }) => country_id)
                    );

                    if (
                        !availableBidsCountries.some(
                            (country) => countries_2.indexOf(country) >= 0
                        )
                    ) {
                        this.fetchingRemainingCountries = true;
                        this.paginationToken = 'fetchRemainingCountries';
                    } else {
                        this.fetchingRemainingCountries = false;
                    }
                }
                this.allBids = paginationToken
                    ? [...this.allBids, ..._.get(data, 'bids')]
                    : _.get(data, 'bids');
                this.bids = this.allBids;

                if (paginationToken) {
                    this.loadingMoreStatus = REQUEST_STATUS.SUCCESS;
                } else {
                    this.fetchingBidsStatus = REQUEST_STATUS.SUCCESS;
                }
            })
            .catch((err: { message: string, }) => {
                if (_.get(err, 'status', null) === 403) {
                    this.statusForbidden = true;
                } else {
                    this.errorMessage = err.message;
                    this.fetchingBidsStatus = REQUEST_STATUS.FAILURE;
                }
            });
    };

    public filterBids = async (searchQuery: string, filterState: number) => {
        const stateString = _.get(bidStates, `${filterState}`);

        const filteredBids = _.isEmpty(searchQuery)
            ? this.allBids
            : _.filter(this.allBids, ({ name, country_id }) => {
                return (
                    _.includes(_.toLower(name), _.toLower(searchQuery)) ||
                    _.includes(
                        _.toLower(countryNameByCode(country_id)),
                        _.toLower(searchQuery)
                    )
                );
            });

        if (_.isEqual(stateString, 'all')) {
            this.bids = filteredBids;
        } else {
            this.bids = _.filter(filteredBids, { state: stateString });
        }
    };

    @observable
    public bidQuotas: Array<BidQuota> = [];

    @observable
    public fetchingQuotasStatus: REQUEST_STATUS | undefined = undefined;

    public fetchQuotaSessions = async (bidID: string, token: string) => {
        this.bidQuotas = [];
        const mappedLucidData =
            await this.rootStore.walrIntegrationService.getSurveyIDs(token, bidID);

        if (_.get(mappedLucidData, 'status', null) === 403) {
            this.statusForbidden = true;
        } else {
            const surveyIDs = _.get(mappedLucidData, 'survey_ids', null);

            this.fetchingQuotasStatus = REQUEST_STATUS.SENDING;
            const bidIndex = _.findIndex(this.allBids, { id: bidID });
            const quotas = _.get(this.allBids[bidIndex], 'quotas');

            await Promise.all(
                _.map(quotas, async ({ id }, index) => {
                    const surveyIndex = _.findIndex(surveyIDs, { quota_id: id });
                    const surveyID = _.get(
                        _.find(surveyIDs, { quota_id: id }),
                        'lucid_survey_id',
                        null
                    );
                    const quotaSessions =
                        await this.rootStore.walrIntegrationService.getQuotaSessions(
                            token,
                            bidID,
                            id
                        );

                    if (_.get(quotaSessions, 'status', null) === 403) {
                        this.statusForbidden = true;
                        return;
                    }
                    const quotaIndex = _.findIndex(this.allBids[bidIndex].quotas, {
                        id: id,
                    });
                    const bidQuota = this.allBids[bidIndex].quotas[quotaIndex];
                    const cpi = _.isNull(surveyIDs)
                        ? null
                        : _.get(surveyIDs[surveyIndex], 'survey_cpi_usd', null);
                    const earnings_per_click = _.isNull(surveyIDs)
                        ? null
                        : _.get(surveyIDs[surveyIndex], 'earnings_per_click', null); //eslint-disable-line
                    const cost_amount = _.isNull(surveyIDs)
                        ? null
                        : _.get(surveyIDs[surveyIndex], 'amount', null);

                    const countSessionStatus = (statusID: string) => {
                        if (_.isEmpty(quotaSessions.sessions)) {
                            return null;
                        } else {
                            const sessions = _.filter(quotaSessions.sessions, {
                                status_id: statusID,
                            });

                            return _.isEmpty(sessions) ? null : _.size(sessions);
                        }
                    };

                    let interview_cost = 0;

                    _.forEach(quotaSessions.sessions, (session) => {
                        if (_.isEqual(session.status_id, 'complete')) {
                            interview_cost += session.interview_cost;
                        }
                    });

                    this.bidQuotas = _.orderBy(
                        [
                            ...this.bidQuotas,
                            {
                                quota_id: bidQuota.id,
                                survey_id: surveyID,
                                bid_id: bidID,
                                quota_name: quotaNameByQualifications(bidQuota.qualifications),
                                cpi,
                                cost_per_interview: bidQuota.cost_per_interview,
                                complete: countSessionStatus('complete'),
                                failure: countSessionStatus('failure'),
                                over_quota: countSessionStatus('over_quota'),
                                quality_termination: countSessionStatus('quality_termination'),
                                screenout: countSessionStatus('screenout'),
                                timeout: countSessionStatus('timeout'),
                                in_progress: countSessionStatus('in_progress'),
                                earnings_per_click,
                                cost_amount,
                                interview_cost: _.isNull(countSessionStatus('complete'))
                                    ? null
                                    : interview_cost,
                                index,
                            },
                        ],
                        ['index'],
                        ['asc']
                    );
                })
            );

            this.fetchingQuotasStatus = REQUEST_STATUS.SUCCESS;
        }
    };

    public onChangeCPI = (survey_id: string | null, cpi: number) => {
        const surveyIndex = _.findIndex(this.bidQuotas, { survey_id: survey_id });

        this.bidQuotas[surveyIndex].cpi = cpi;
    };

    @observable
    public updatingSurveyID = '';

    @observable
    public updatingSurveyCPIStatus: REQUEST_STATUS | undefined = undefined;

    public updateSurveyCPI = (
        token: string,
        survey_id: string | null,
        cpi: number
    ) => {
        if (!_.isNull(survey_id)) {
            this.updatingSurveyID = survey_id;
            this.updatingSurveyCPIStatus = REQUEST_STATUS.SENDING;

            this.rootStore.walrIntegrationService
                .updateSurveyCPI(token, survey_id, cpi)
                .then((_data: any) => {
                    this.updatingSurveyCPIStatus = REQUEST_STATUS.SUCCESS;
                })
                .catch((err: { message: string, }) => {
                    if (_.get(err, 'status', null) === 403) {
                        this.statusForbidden = true;
                    } else {
                        this.updatingSurveyCPIStatus = REQUEST_STATUS.FAILURE;
                    }
                });
        }
    };

    @observable
    public changingPasswordStatus: REQUEST_STATUS | undefined = undefined;

    public changePassword = async (token: string, password: string) => {
        this.errorMessage = '';
        this.changingPasswordStatus = REQUEST_STATUS.SENDING;
        this.rootStore.walrIntegrationService
            .changePassword(token, password)
            .then((_data: any) => {
                this.changingPasswordStatus = REQUEST_STATUS.SUCCESS;
            })
            .catch((err: { detail: string, }) => {
                if (_.get(err, 'status', null) === 403) {
                    this.statusForbidden = true;
                } else {
                    this.errorMessage = err.detail;
                    this.changingPasswordStatus = REQUEST_STATUS.FAILURE;
                }
            });
    };

    public updateChangingPasswordStatus = () => {
        this.changingPasswordStatus = undefined;
    };

    @observable
    public exportExcelStatus: REQUEST_STATUS | undefined = undefined;

    public exportExcel = async (
        rangeStart: string,
        rangeEnd: string,
        includeQuotaLevel: boolean,
        isLoading: any
    ) => {
        this.errorMessage = '';
        this.exportExcelStatus = REQUEST_STATUS.SENDING;
        this.rootStore.walrIntegrationService
            .exportExcel(rangeStart, rangeEnd, includeQuotaLevel, isLoading)
            .then((_data: any) => {
                this.exportExcelStatus = REQUEST_STATUS.SUCCESS;
            })
            .catch((err: { detail: string, }) => {
                if (_.get(err, 'status', null) === 403) {
                    this.statusForbidden = true;
                } else {
                    this.errorMessage = err.detail;
                    this.exportExcelStatus = REQUEST_STATUS.FAILURE;
                }
            });
    };

    public updateExportexcelStatus = () => {
        this.exportExcelStatus = undefined;
    };
}
