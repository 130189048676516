import { createContext } from 'react';
import { WalrIntegrationServiceProps } from './common/services';

import { WalrIntegrationService } from './common/services/WalrIntegrationService'
import {
    MorningConsultSamplerStore
    , MorningConsultSamplerStoreProps
} from './modules/MorningConsultSampler/stores/MorningConsultSamplerStore';

class RootStore {
    walrIntegrationService: WalrIntegrationServiceProps;

    morningConsultSamplerStore: MorningConsultSamplerStoreProps;

    constructor() {
        this.walrIntegrationService = new WalrIntegrationService();
        this.morningConsultSamplerStore = new MorningConsultSamplerStore(this);
    }
}

export const rootStore = new RootStore();

export const storesProvider = createContext(rootStore);
