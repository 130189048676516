import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import styled from "styled-components";
import LoadingSpinner from '../../../../common/components/LoadingSpinner/LoadingSpinner';
import { BID_STATUS_LIST, expandedListHeaders, headers, REQUEST_STATUS } from "../../../../common/constants";
import { countryNameByCode } from '../../../../common/helpers';
import { storesProvider } from '../../../../rootStore';
import ExportSettingsModal from '../../components/ExportSettingsModal/ExportSettingsModal';

const FilteringTools = styled.div`
    display: flex;
    padding: 1em 1.5em;
    justify-content:space-between;
`

const StatusRow = styled.div`
    display: flex;
    min-width: 35%;
    align-items: center;
`
const StatusButton = styled.button`
    font-weight: 500;
    flex: 1 1 0px;
    margin: 0 0.25em;

    &.btn-active{
        border-color: #C3F4EA;
        background-color: #C3F4EA;
    }

    &:hover{
        border-color: #C3F4EA;
    }
`

const SearchBar = styled.input`
    min-width: 35%;
    width: auto;
`

const ListContainer = styled.div`
    padding: 1em 1.5em;
`

const List = styled.div`
    display: flex;
    flex-direction: column;
`

const ListRow = styled.div`
    display: flex;
    cursor: pointer;
    padding: 1em;
    justify-content: space-around;
    align-items: center;

    span {
        flex: 1 1 0px;
        font-size: 14px;
    }

    &.expanded {
        background-color: #C3F4EA;
    }
`

const ListHeader = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 0.5em 1em;
    align-items: center;
`

const HeaderItem = styled.span`
    flex: 1 1 0px;
    font-size: 11px;
    color: #636464;
`

const ExportButton = styled.button`
    background-color: #C3F4EA;
    min-width: 200px;
    align-self: flex-end;
    margin: 1em;

    &:hover {
        background-color: #addbd2;
        border-color: #addbd2;
    }
`

const ExpandedArea = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 1em 1em;
    background-color: #fff;
`

const ExpandedList = styled.div`
    display: flex;
    flex-direction: column;
`

const ExpandedListRow = styled.div`
    display: flex;
    padding: 1em;
    justify-content: space-around;
    align-items: center;

    span {
        flex: 1 1 0px;
        font-size: 12px;

        &.disabled {
            cursor: not-allowed;
            color: #bbb;
        }
    }

    a {
        text-decoration: none;
        color: #212529;

        svg {
            width: 10px;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`

const NoDataMessage = styled.div`
    font-size: 15px;
    margin: 2em;
    text-align: center;
`

const LoadMoreButton = styled.button`
    margin: 0 1.5em 1em;
    display: flex;
    align-items: center;
    justify-content: center;

    &.btn:disabled {
        cursor: not-allowed;
        pointer-events: auto;
    }
`

const CPIInput = styled.input`
    flex: 1 1 0px;
    font-size: 12px;
    border-width: 0;
    padding: 0;
    background: transparent;
    text-align: center;
    width: 80%;
    margin: 0 auto;

    &:focus {
        background: transparent;
        border-width: 0 0 1px;
        box-shadow: none;
        border-color: #c3f4ea;
    }
`

const UpdatingText = styled.span`
    text-align: center;
    color: #636464;
    display: flex;
    justify-content: center;
`

const BidsOverview = observer(() => {
    const navigate = useNavigate();
    const [expandedItemID, setExpandedItemID] = useState<string | null>(null);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [stateFilter, setStateFilter] = useState<number>(2);
    const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
    const { morningConsultSamplerStore } = useContext(storesProvider);
    const {
        allBids,
        bids,
        fetchBids,
        fetchingBidsStatus,
        filterBids,
        fetchQuotaSessions,
        bidQuotas,
        paginationToken,
        onChangeCPI,
        updateSurveyCPI,
        fetchingQuotasStatus,
        loadingMoreStatus,
        updatingSurveyID,
        updatingSurveyCPIStatus,
        statusForbidden} = morningConsultSamplerStore
    const token = localStorage.getItem(`${process.env.REACT_APP_USER_TOKEN}`);

    useEffect(() => {
        if (_.isNull(token)) {
            navigate('/login');
        }
    }, [])

    useEffect(() => {
        if (statusForbidden) {
            localStorage.removeItem(`${process.env.REACT_APP_USER_TOKEN}`);
            navigate('/login');
        }
    }, [statusForbidden])

    useEffect(() => {
        if (_.isEmpty(bids) && _.isUndefined(fetchingBidsStatus) && !_.isNull(token)) {
            fetchBids(token);
        }
    }, [bids])

    useEffect(() => {
        filterBids(searchQuery, stateFilter)
    }, [searchQuery, stateFilter]);

    const onExpandItem = (id: string) => {
        if (!_.isNull(token)) {
            fetchQuotaSessions(id, token);
            setExpandedItemID(_.isEqual(expandedItemID, id) ? null : id);
        }
    };

    const onSearch = (value: string) => {
        setSearchQuery(value);
    }

    const loadMoreBids = () => {
        if (!_.isNull(token)) {
            fetchBids(token, paginationToken);
            setSearchQuery('');
            setStateFilter(2);
        }
    }

    const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, survey_id: string | null) => {
        if (event.key === 'Enter' && !_.isNull(token)) {
            updateSurveyCPI(token, survey_id, _.toNumber((event.target as HTMLInputElement).value));
        }
    };

    const isUpdatingSurvey = (survey_id: string | null) => {
        return _.isEqual(updatingSurveyID, survey_id) &&
            _.isEqual(updatingSurveyCPIStatus, REQUEST_STATUS.SENDING)
    }
    const LucidLink = ({ survey_id }: { survey_id: string | null, }) => {
        if (_.isNull(survey_id)) {
            return (
                <span className='disabled' title='Lucid survey ID not available.'>
                    Lucid
                    <FontAwesomeIcon icon={faUpRightFromSquare} className="ms-2" />
                </span>
            )
        } else {
            return (

                <span className='text-center'>
                    <a href={`https://www.samplicio.us/fulcrum/next/surveys/${survey_id}/quotas`}
                        target='_blank' rel="noreferrer"
                    >
                        Lucid
                        <FontAwesomeIcon icon={faUpRightFromSquare} className="ms-2" />
                    </a>
                </span>
            )
        }
    }

    const UpdatingRow = ({ quotaIndex }: { quotaIndex: number, }) => {
        return (
            <ExpandedListRow
                className={_.isEqual(quotaIndex, bidQuotas.length - 1)
                    ? 'border-bottom-0' :
                    'border-bottom'}>
                <UpdatingText>
                    Updating survey
                    <div className='spinner-border spinner-border-sm ms-2' role="status" />
                </UpdatingText>
            </ExpandedListRow>
        )
    }

    return (
        <div className='container d-flex flex-column'>
            {isExportModalOpen &&
                <ExportSettingsModal onClose={() => setIsExportModalOpen(false)} />
            }
            <FilteringTools>
                <StatusRow>
                    {_.map(BID_STATUS_LIST, (status, index) => (
                        <StatusButton
                            key={index}
                            onClick={() => { setStateFilter(index); filterBids(searchQuery, index) }}
                            className={`btn ${_.isEqual(index, stateFilter) ? 'btn-active' : ''}`}
                        >
                            {_.upperCase(status)}
                        </StatusButton>
                    ))}
                </StatusRow>
                <div className='d-flex align-items-center'>
                    <SearchBar
                        className='form-control'
                        placeholder='Search...'
                        value={searchQuery}
                        onChange={(e: { target: { value: string, }, }) => onSearch(e.target.value)} />
                    <ExportButton
                        className='btn btn-light border'
                        disabled={_.isEmpty(allBids)}
                        onClick={() => setIsExportModalOpen(true)}>
                        Export
                    </ExportButton>
                </div>
            </FilteringTools>
            {fetchingBidsStatus === REQUEST_STATUS.SENDING &&
                <LoadingSpinner />
            }
            {fetchingBidsStatus === REQUEST_STATUS.SUCCESS &&
                <>
                    <ListContainer className='container'>
                        <ListHeader>
                            {_.map(headers, (header, index) => (
                                <HeaderItem key={index} className={index > 0 ? 'text-center' : 'text-left'}>
                                    {_.toUpper(header)}
                                </HeaderItem>
                            ))}
                        </ListHeader>
                        {!_.isEmpty(bids) ?
                            <List className='border bg-light'>
                                {_.map(bids,
                                    ({ id, country_id, statistics }, index) => (
                                        <div key={index}>
                                            <ListRow
                                                data-testid={`bid-${index}`}
                                                onClick={() => onExpandItem(id)}
                                                //eslint-disable-next-line
                                                className={`${_.isEqual(index, bids.length - 1) ? 'border-bottom-0' : 'border-bottom'}
                                    ${_.isEqual(expandedItemID, id) ? 'expanded' : ''}`}>
                                                <span className='text-left'>{countryNameByCode(country_id)}</span>
                                                <span className='text-center'>{id} </span>
                                                <span className='text-center'>{statistics.num_available} </span>
                                                <span className='text-center'>{statistics.num_completes} </span>
                                                <span className='text-center'>{statistics.num_in_progress} </span>
                                                <span className='text-center'>{statistics.num_over_quotas} </span>
                                                <span className='text-center'>{statistics.qualified_conversion}</span>
                                            </ListRow>
                                            {_.isEqual(id, expandedItemID) &&
                                                <ExpandedArea
                                                    className={_.isEqual(index, bidQuotas.length - 1)
                                                        ? 'border-bottom-0' :
                                                        'border-bottom'}
                                                >
                                                    <ListHeader>
                                                        {_.map(expandedListHeaders, (header, key) => (
                                                            <HeaderItem
                                                                key={key}
                                                                className={key > 0 ? 'text-center' : 'text-left'}
                                                            >
                                                                {_.toUpper(header)}
                                                            </HeaderItem>
                                                        ))}
                                                    </ListHeader>
                                                    {fetchingQuotasStatus === REQUEST_STATUS.SENDING &&
                                                        <LoadingSpinner />
                                                    }
                                                    {fetchingQuotasStatus === REQUEST_STATUS.SUCCESS &&
                                                        !_.isEmpty(bidQuotas) &&
                                                        <ExpandedList className='border bg-light'>
                                                            {_.map(bidQuotas,
                                                                (
                                                                    {
                                                                        survey_id,
                                                                        quota_id,
                                                                        quota_name,
                                                                        cpi,
                                                                        cost_per_interview,
                                                                        complete,
                                                                        failure,
                                                                        over_quota,
                                                                        quality_termination,
                                                                        screenout,
                                                                        timeout,
                                                                        in_progress,
                                                                        earnings_per_click,
                                                                        cost_amount,
                                                                        interview_cost
                                                                    }, quotaIndex) => (
                                                                    isUpdatingSurvey(survey_id) ?
                                                                        <UpdatingRow
                                                                            quotaIndex={quotaIndex} />
                                                                        :
                                                                        <ExpandedListRow
                                                                            key={quotaIndex}
                                                                            className={_.isEqual(quotaIndex, bidQuotas.length - 1)
                                                                                ? 'border-bottom-0' :
                                                                                'border-bottom'}>
                                                                            <span className='text-left'>
                                                                                {_.capitalize(quota_name)}
                                                                            </span>
                                                                            <span className='text-center'>
                                                                                {quota_id}
                                                                            </span>
                                                                            {_.isNull(cpi) ?
                                                                                <span className='text-center'>
                                                                                    -
                                                                                </span>
                                                                                :
                                                                                <span className='w-100'>
                                                                                    <CPIInput
                                                                                        type="number"
                                                                                        className='form-control'
                                                                                        defaultValue={cpi}
                                                                                        onChange={(event: { target: { value: string, }, }) => // eslint-disable-line
                                                                                            onChangeCPI(survey_id, _.toNumber(event.target.value))} // eslint-disable-line
                                                                                        onBlur={(event: { target: { value: string, }, }) => { //eslint-disable-line
                                                                                            if (!_.isNull(token)) {
                                                                                                updateSurveyCPI(token, survey_id, _.toNumber(event.target.value)) // eslint-disable-line
                                                                                            }
                                                                                        }}
                                                                                        onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => // eslint-disable-line
                                                                                            onKeyPress(event, survey_id)}
                                                                                    />
                                                                                </span>
                                                                            }
                                                                            <span className='text-center'>
                                                                                {
                                                                                    _.isNull(cost_per_interview)
                                                                                        ? '-'
                                                                                        :
                                                                                        cost_per_interview
                                                                                }
                                                                            </span>
                                                                            <span className='text-center'>
                                                                                {_.isNull(complete) ? '-' : complete}
                                                                            </span>
                                                                            <span className='text-center'>
                                                                                {_.isNull(failure) ? '-' : failure}
                                                                            </span>
                                                                            <span className='text-center'>
                                                                                {_.isNull(over_quota) ? '-' : over_quota}
                                                                            </span>
                                                                            <span className='text-center'>
                                                                                {
                                                                                    _.isNull(quality_termination) ?
                                                                                        '-' :
                                                                                        quality_termination
                                                                                }
                                                                            </span>
                                                                            <span className='text-center'>
                                                                                {_.isNull(screenout) ? '-' : screenout}
                                                                            </span>
                                                                            <span className='text-center'>
                                                                                {_.isNull(timeout) ? '-' : timeout}
                                                                            </span>
                                                                            <span className='text-center'>
                                                                                {_.isNull(in_progress) ? '-' : in_progress}
                                                                            </span>
                                                                            <span className='text-center'>
                                                                                {
                                                                                    _.isNull(earnings_per_click)
                                                                                        ? '-'
                                                                                        :
                                                                                        _.toNumber(earnings_per_click)
                                                                                }
                                                                            </span>
                                                                            <span className='text-center'>
                                                                                {_.isNull(interview_cost) ? '-' : interview_cost}
                                                                            </span>
                                                                            <span className='text-center'>
                                                                                {_.isNull(cost_amount)
                                                                                    ? '-'
                                                                                    :
                                                                                    _.toNumber(cost_amount)}
                                                                            </span>
                                                                            <LucidLink survey_id={survey_id} />
                                                                        </ExpandedListRow>
                                                                ))
                                                            }
                                                        </ExpandedList>
                                                    }
                                                    {
                                                        fetchingQuotasStatus !== REQUEST_STATUS.SENDING
                                                        && _.isEmpty(bidQuotas) &&
                                                        <NoDataMessage>
                                                            No data found.
                                                        </NoDataMessage>
                                                    }
                                                </ExpandedArea>}
                                        </div>
                                    ))}
                            </List>
                            : <NoDataMessage>
                                No data found.
                            </NoDataMessage>
                        }
                    </ListContainer>
                    <LoadMoreButton
                        className='btn btn-sm btn-light'
                        onClick={loadMoreBids}
                        disabled={_.isUndefined(paginationToken) || _.isEmpty(bids)}>
                        Load more
                        {loadingMoreStatus === REQUEST_STATUS.SENDING &&
                            <div className='spinner-border spinner-border-sm ms-2' role="status" />
                        }
                    </LoadMoreButton>
                </>
            }
        </div >
    );
});

export default BidsOverview;
