import _ from "lodash";
import { mapEducation, mapGender } from "../constants";

export const countryNameByCode = (code: string) => {
    const countryName = new Intl.DisplayNames(
        ['en'], { type: 'region' }
    );

    return countryName.of(_.toUpper(code));
}

export const quotaNameByQualifications = (qualfications: Array<{ id: string, response_ids: Array<string>, }>) => {
    let quotaName: Array<string> = [];

    if (!_.isEmpty(_.filter(qualfications, { id: 'gender' }))) {
        const genderList = _.map(_.filter(qualfications, { id: 'gender' })[0].response_ids, (id: string) => {
            return _.get(mapGender, `${id}`);
        });

        quotaName = [`${_.join(genderList, ', ')}`];
    }
    if (!_.isEmpty(_.filter(qualfications, { id: 'age' }))) {
        const ageList = simplifyAgeString(_.filter(qualfications, { id: 'age' })[0].response_ids);

        quotaName = [...quotaName, `age: ${_.join(ageList, ', ')}`];
    }
    if (!_.isEmpty(_.filter(qualfications, { id: 'education' }))) {
        const educationList = _.map(_.filter(qualfications, { id: 'education' })[0].response_ids, (id: string) => {
            return _.get(mapEducation, `${id}`);
        });

        quotaName = [...quotaName, `education: ${_.join(educationList, ', ')}`];
    }

    return `${_.join(quotaName, ', ')}.`

}

export const simplifyAgeString = (ageList: Array<string>) => {
    const list = _.sortBy(_.map(ageList,(age)=>{return _.toNumber(age)}));
 
    let simplifiedAgeList: Array<{ rangeMin: number, rangeMax: number, }> = [];
    let rangeMin = list[0];
    let rangeMax = list[0];

    _.forEach(list, (age, index) => {
        const prevAge = _.isEqual(index, 0) ? age - 1 : list[index - 1];
       
        if (_.isEqual(prevAge + 1, age)) {
            rangeMax = age;
            if (_.isEmpty(_.filter(simplifiedAgeList, { rangeMin: rangeMin }))) {
                simplifiedAgeList = [...simplifiedAgeList, { rangeMin: rangeMin, rangeMax: rangeMax }];
            } else {
                _.filter(simplifiedAgeList, { rangeMin: rangeMin })[0].rangeMax = age;
            }
        } else {
            simplifiedAgeList = [...simplifiedAgeList, { rangeMin: age, rangeMax: age }];
            rangeMin = age;
            rangeMax = age;
        }
    })

    return _.map(simplifiedAgeList, (item) => {
        return _.isEqual(item.rangeMin, item.rangeMax) ? `${item.rangeMin}` : `${item.rangeMin} - ${item.rangeMax}`
    })
}