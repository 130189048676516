import React, { useState, useContext, Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import _ from 'lodash';
import ErrorAlert from '../../../../common/components/ErrorAlert/ErrorAlert';
import { storesProvider } from '../../../../rootStore';

const Modal = styled.div`
    display: block;
    background-color: #00000070;
    
    .modal-content {
        border-radius: 0;
        border: 0;
    }

    .form-check-input:checked {
        background-color: #C3F4EA;
        border-color: #C3F4EA;
    }

    .form-control:focus {
        border-color: #C3F4EA;
        box-shadow: 0 0 0 0.25rem #addbd278;
    }

    .form-check-input:focus {
        border-color: #C3F4EA;
        outline: 0;
        box-shadow: 0 0 0 0.25rem #addbd278;
`;

const ExportButton = styled.button`
  background-color: #c3f4ea;

  &:hover {
    background-color: #addbd2;
    border-color: #addbd2;
  }
`;

const ExportSettingsModal = observer(({ onClose }: { onClose: () => void, }) => {
    const [loading, isLoading] = useState<boolean>(false)
    const [rangeStart, setRangeStart] = useState<string>('');
    const [rangeEnd, setRangeEnd] = useState<string>('');
    const [includeQuotaLevel, setIncludeQuotaLevel] = useState<boolean>(false);
    const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
    const { morningConsultSamplerStore } = useContext(storesProvider);
    const {
        exportExcel,
        errorMessage,
    } = morningConsultSamplerStore;
    const today = new Date().toISOString().split('T')[0];

    const onSubmit = (event: React.FormEvent, includeQuota: boolean) => {
        event.preventDefault();
        exportExcel(rangeStart, rangeEnd, includeQuota, isLoading);
    };

    return (
        <Modal className="modal" role="dialog">
            <form onSubmit={(event) => onSubmit(event, includeQuotaLevel)}>
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Export settings</h5>
                            <button type="button" className="btn close" onClick={onClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body d-flex flex-column">
                            <span className="ps-2 mb-2">Select export data date range:</span>
                            <div className="d-flex">
                                <input
                                    className="form-control me-2"
                                    type="date"
                                    onChange={(e: { target: { value: string, }, }) =>
                                        setRangeStart(e.target.value)
                                    }
                                    value={rangeStart}
                                    max={today}
                                    required
                                />
                                <input
                                    className="form-control"
                                    type="date"
                                    min={_.isEmpty(rangeStart) ? undefined : rangeStart}
                                    max={today}
                                    onChange={(e: { target: { value: string, }, }) =>
                                        setRangeEnd(e.target.value)
                                    }
                                    value={rangeEnd}
                                    required
                                />
                            </div>
                            <div className="form-check mt-5">
                                <input
                                    className="form-check-input rounded-0"
                                    id="includeQuotaLevel"
                                    type="checkbox"
                                    checked={includeQuotaLevel}
                                    onChange={(e: { target: { checked: boolean, }, }) =>
                                        setIncludeQuotaLevel(e.target.checked)
                                    }
                                />
                                <label className="form-check-label" htmlFor="includeQuotaLevel">
                                    Include quota level data
                                </label>
                            </div>
                        </div>
                        {showErrorAlert && (
                            <ErrorAlert
                                message={errorMessage}
                                onClose={() => setShowErrorAlert(false)}
                            />
                        )}
                        <div className="modal-footer">
                            <ExportButton className="btn" type="submit" disabled={loading && true}>
                                {loading ?
                                    <Fragment>
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true">
                                        </span>
                                        Loading...
                                    </Fragment>
                                    : "Export data"
                                }
                            </ExportButton>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={onClose}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    );
});

export default ExportSettingsModal;
