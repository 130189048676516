import React from "react";
import styled from "styled-components";

const Error = styled.div`
    display: flex;
    align-items: start;
    margin-top: 1em;
    justify-content: space-between;
    padding: 1em;
`

const ErrorAlert = ({message,onClose}:{message: string, onClose: () => void,}) => {
    return (
        <Error className='alert alert-danger fade show' role='alert'>
            <div className='d-flex flex-column'>
                <strong className='me-1'>Error: </strong>
                <p>
                    {message}
                </p>
            </div>
            <button
                className='btn close'
                onClick={onClose}
            >
                <span aria-hidden='true'>&times;</span>
            </button>
        </Error>
    )
}

export default ErrorAlert;