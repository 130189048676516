import _ from 'lodash';

export class WalrIntegrationService {
    async createUser(
        email: string,
        password: string,
        firstname: string,
        lastname: string
    ) {
        const response = await fetch(
            `${process.env.REACT_APP_WALR_INTEGRATION_API_URL}/api/v1/users/`,
            {
                method: 'POST',
                redirect: 'follow',
                mode: 'cors',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                    firstname: firstname,
                    lastname: lastname,
                }),
            }
        );

        if (!response.ok) {
            if (_.isEqual(response.status, 400)) {
                await response.json().then((res) => {
                    throw res;
                });
            } else {
                throw { detail: 'An error occured, please try again.' };
            }
        }

        return response.json();
    }

    async userLogin(email: string, password: string) {
        const response = await fetch(
            `${process.env.REACT_APP_WALR_INTEGRATION_API_URL}/api/v1/login`,
            {
                method: 'POST',
                redirect: 'follow',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: new URLSearchParams({
                    username: email,
                    password: password,
                }),
            }
        );

        if (!response.ok) {
            if (_.isEqual(response.status, 400)) {
                await response.json().then((res) => {
                    throw res;
                });
            } else {
                throw { detail: 'An error occured, please try again.' };
            }
        }

        return response.json();
    }

    async getSurveyIDs(token: string, bidID: string) {
        const response = await fetch(
            `${process.env.REACT_APP_WALR_INTEGRATION_API_URL}/api/v1/sampler` +
            `/survey-ids?bid_id=${bidID}`,
            {
                method: 'GET',
                redirect: 'follow',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (!response.ok) {
            if (_.isEqual(response.status, 400)) {
                await response.json().then((res) => {
                    return res;
                });
            } else if (_.isEqual(response.status, 403)) {
                return { status: 403 };
            } else {
                return { detail: 'An error occured, please try again.' };
            }
        }

        return response.json();
    }

    async mapMCBidsToLucid(token: string) {
        const response = await fetch(
            `${process.env.REACT_APP_WALR_INTEGRATION_API_URL}/api/v1/sampler`,
            {
                method: 'POST',
                redirect: 'follow',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (!response.ok) {
            if (_.isEqual(response.status, 400)) {
                await response.json().then((res) => {
                    throw res;
                });
            } else {
                throw { detail: 'An error occured, please try again.' };
            }
        }

        return response.json();
    }

    async getSupplierBids(
        token: string,
        countries: Array<string>,
        pagination_token?: string
    ) {
        const body = pagination_token
            ? {
                country_id: countries,
                pagination_token: pagination_token,
            }
            : {
                country_id: countries,
            };

        const response = await fetch(
            `${process.env.REACT_APP_WALR_INTEGRATION_API_URL}/api/v1/supplier/bids`,
            {
                method: 'POST',
                redirect: 'follow',
                mode: 'cors',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            }
        );

        if (!response.ok) {
            if (_.isEqual(response.status, 400)) {
                await response.json().then((res) => {
                    throw res;
                });
            } else if (_.isEqual(response.status, 403)) {
                throw { status: 403 };
            } else {
                throw { detail: 'An error occured, please try again.' };
            }
        }

        return response.json();
    }

    async getQuotaSessions(token: string, bidID: string, quotaID: string) {
        const response = await fetch(
            `${process.env.REACT_APP_WALR_INTEGRATION_API_URL}/api/v1/` +
            `supplier/sessions?bid_id=${bidID}&quota_id=${quotaID}`,
            {
                method: 'GET',
                redirect: 'follow',
                mode: 'cors',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (!response.ok) {
            if (_.isEqual(response.status, 400)) {
                await response.json().then((res) => {
                    return res;
                });
            } else if (_.isEqual(response.status, 403)) {
                return { status: 403 };
            } else {
                return { detail: 'An error occured, please try again.' };
            }
        }

        return response.json();
    }

    async updateSurveyCPI(token: string, surveyID: string, cpi: number) {
        const response = await fetch(
            `${process.env.REACT_APP_WALR_INTEGRATION_API_URL}/api/v1/` +
            `sampler/survey-ids/update-survey-cpi?survey_id=${surveyID}&survey_cpi_usd=${cpi}`,
            {
                method: 'GET',
                redirect: 'follow',
                mode: 'cors',
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                },
            }
        );

        if (!response.ok) {
            if (_.isEqual(response.status, 400)) {
                await response.json().then((res) => {
                    return res;
                });
            } else if (_.isEqual(response.status, 403)) {
                throw { status: 403 };
            } else {
                return { detail: 'An error occured, please try again.' };
            }
        }

        return response.json();
    }

    async changePassword(token: string, password: string) {
        const response = await fetch(
            `${process.env.REACT_APP_WALR_INTEGRATION_API_URL}/api/v1/users` +
            `/change-password?password=${password}`,
            {
                method: 'POST',
                redirect: 'follow',
                mode: 'cors',
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                },
            }
        );

        if (!response.ok) {
            if (_.isEqual(response.status, 400)) {
                await response.json().then((res) => {
                    throw res;
                });
            } else if (_.isEqual(response.status, 403)) {
                throw { status: 403 };
            } else {
                throw { detail: 'An error occured, please try again.' };
            }
        }

        return response.json();
    }
    async exportExcel(
        rangeStart: string,
        rangeEnd: string,
        includeQuotaLevel: any,
        isLoading: any
    ) {
        const token = localStorage.getItem(`${process.env.REACT_APP_USER_TOKEN}`);

        isLoading(true)
        fetch(
            `${process.env.REACT_APP_WALR_INTEGRATION_API_URL}` +
            `/api/v1/sampler/export` +
            `?start_date=${rangeStart}&end_date=${rangeEnd}&include_survey_data=${includeQuotaLevel}`,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                },
            }
        ).then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');

                isLoading(false)
                link.href = url;
                link.setAttribute('download', 'mydata.xlsx');
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.error('There was a problem downloading the file:', error);
            });
    }
}
