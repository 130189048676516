import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import ErrorAlert from '../../../../common/components/ErrorAlert/ErrorAlert';
import { REQUEST_STATUS } from '../../../../common/constants';
import { User } from '../../../../common/interfaces';
import { storesProvider } from '../../../../rootStore';

const Form = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2.5em;
    min-width: 500px;
`
const LoginButton = styled.button`
    background-color: #C3F4EA;
    width: 100%;

    &:hover {
        background-color: #addbd2;
        border-color: #addbd2;
    }
`

const SuccessAlert = styled.div`
    display : flex;
    padding : 1em;
    margin-top: 1em;
    align-items: center;
    justify-content: center;
`

const Login = observer(() => {
    const navigate = useNavigate();
    const [user, setUserData] = useState<User>({ email: '', password: '' });
    const [showCreatedAccountSuccessAlert, setShowCreatedAccountSuccessAlert] = useState<boolean>(false);
    const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
    const { morningConsultSamplerStore } = useContext(storesProvider);
    const { logIn, loggingUserInStatus, errorMessage } = morningConsultSamplerStore;

    useEffect(() => {
        if (_.isEqual(localStorage.getItem('createdUser'), 'true')) {
            setShowCreatedAccountSuccessAlert(true);
        }
    }, [localStorage])

    useEffect(() => {
        const token = localStorage.getItem(`${process.env.REACT_APP_USER_TOKEN}`);

        if ((loggingUserInStatus === REQUEST_STATUS.SUCCESS || _.isUndefined(loggingUserInStatus))
            && !_.isNull(token)) {
            navigate('/')
        }

        if (loggingUserInStatus === REQUEST_STATUS.FAILURE) {
            setShowErrorAlert(true);
        }

    }, [loggingUserInStatus]);

    const onEmailChange = (e: { target: { value: string, }, }) => {
        setUserData({ ...user, email: e.target.value });
    };

    const onPasswordChange = (e: { target: { value: string, }, }) => {
        setUserData({ ...user, password: e.target.value });
    }

    const CreatedUserSuccessAlert = () => {
        return (
            <SuccessAlert className='alert alert-success fade show' role='alert'>
                <strong className='me-1'>Your account has been created! </strong>
                Please log in using your email and password.
                <button
                    className='btn close'
                    onClick={() => {
                        localStorage.setItem('createdUser', 'false');
                        setShowCreatedAccountSuccessAlert(false);
                    }}
                >
                    <span aria-hidden='true'>&times;</span>
                </button>
            </SuccessAlert>
        )
    }

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        logIn(user.email, user.password);
    }

    return (
        <div className='container d-flex flex-column align-items-center'>
            {showCreatedAccountSuccessAlert &&
                <CreatedUserSuccessAlert />
            }

            <Form>
                <form onSubmit={onSubmit}>
                    <h3 className='text-center mb-3'>Log in</h3>
                    <h5 className='text-center mb-5'>Please enter your email and password</h5>
                    <input
                        required
                        type='email'
                        className='form-control form-control-lg mb-3'
                        placeholder='Email'
                        onChange={onEmailChange} />
                    <input
                        required
                        type='password'
                        className='form-control form-control-lg mb-3'
                        placeholder='Password'
                        onChange={onPasswordChange} />
                    {showErrorAlert &&
                        <ErrorAlert message={errorMessage} onClose={() => setShowErrorAlert(false)} />}
                    <LoginButton
                        className='btn btn-lg mb-5 border'
                        type='submit'
                    >
                        Login
                    </LoginButton>
                </form>
            </Form>
        </div>
    );
});

export default Login;
